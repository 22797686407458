import React, { useState, useRef, useEffect }  from 'react';
import { useTranslation } from 'react-i18next';
import { FormikProps, useFormik } from 'formik';
import { Button, Form, Tab, Nav, Card, Container, Col, InputGroup }  from 'react-bootstrap';
import { Whatsapp } from 'react-bootstrap-icons';
import axios from "axios";
import Swal from 'sweetalert2';

import VerificationCodeButton from './components/VerificationCodeButton';

import logo from '../../assets/logo.png';

import '../../i18n';
import './style.css';

import FormPF from './form-pf';
import FormPJ from './form-pj';

import * as Yup from "yup";

type urlParams  = {
  produto?: string;
  franchise_id?: number;
  representante?: string;
  cupom?: string;
}

type FirstStepParams = {
  email1: string,
  password: string,
  confirm:  string,
  code: string,
  authorization: boolean
};

const Home = () => {
  const baseURL = "https://asn.apihn.co/api";

  const { t } = useTranslation();
  
  const [isShown, setIsShown] = useState(false);
  const [cupom, setCupom] = useState<string|null>(null);
  const [tabAccountInfo, setTabAccountInfo] = useState('newAccount');
  const [firstStepValues, setFirstStepVaLues] = useState<FirstStepParams>({
    email1: '',
    password: '',
    confirm:  '',
    code: '',
    authorization: false
  });
  const [codeSentMessage, setCodeSentMessage] = useState("");

  const queryParams = new URLSearchParams(window.location.search);
  const produto = queryParams.get('produto');
  const idRepresentante = queryParams.get('rep');
  const representante = queryParams.get('representante');
  const redirect = queryParams.get('redirect');

  const formRef = useRef<HTMLDivElement>(null);
  const formRefFirstStep = useRef<HTMLFormElement>(null);

  const handleClickNextStep = async (values: FirstStepParams) => {
    try { 
      await axios.put(`${baseURL}/verification-codes`, {
        email: values.email1,
        code: values.code,
      });
      setIsShown(true);
      setFirstStepVaLues(values);
    } catch (error) {
      Swal.fire(
        'Código inválido',
        'Não foi possível validar o código informado. Por favor, solicite um novo código de verificação.',
        'error'
      );
    }
  };

  useEffect(() => {
    const getParams: urlParams = {};
    let date = new Date();
    date.setTime(date.getTime() + (1/*dias*/ * 24 * 60 * 60 * 1000));
    let expires = "expires=" + date.toUTCString();
    //let path = ";path=/;domain=hostnet.com.br";
    let path = ';path=/;';
    if (produto) {
      getParams['produto'] = produto;
      document.cookie = 'produto' + "=" + produto + ";" + expires + path;
    }
    if (idRepresentante) {
      getParams['franchise_id'] = parseInt(idRepresentante);
      document.cookie = 'rep' + "=" + idRepresentante + ";" + expires + path;
    }
    if (representante) {
      getParams['representante'] = representante;
      document.cookie = 'representante' + "=" + representante + ";" + expires + path;
    }
    if (redirect && /^(https?:\/\/)?([\da-z.-]+)\.([a-z.]{2,6})([\/\w .-]*)*\/?$/.test(redirect)) {
      if (redirect.includes(".hostnet.com.br")) {
        //window.alert(redirect);
        window.location.href = redirect;
      }
    }
  }, [produto, idRepresentante, representante, redirect]);

  useEffect(() => {
    if (isShown === true && formRef && formRef.current) {
        formRef.current.scrollIntoView();
    }
  }, [isShown, formRef]);

  useEffect(() => {
    let cookieCupom = document.cookie.replace(/(?:(?:^|.*;\s*)StCupomCodigo\s*\=\s*([^;]*).*$)|^.*$/, "$1");
    setCupom(cookieCupom);
  }, []);
  
  const formik = useFormik({
    validateOnMount: true,
    enableReinitialize: true,
    initialValues: {
      email1: '',
      password: '',
      confirm:  '',
      code: '',
      authorization: false
    },
    validationSchema: Yup.object().shape({
      email1: Yup.string()
        .required(t('validations:requiredField'))
        .email(t('validations:invalidEmail')),
      password: Yup.string()
        .required(t('validations:password'))
        .min(8, t('validations:passwordMin'))
        .matches(RegExp('^(?=.*[0-9])'), t('validations:passwordNumber'))
        .matches(RegExp('^(?=.*[a-z])'), t('validations:passwordLower'))
        .matches(RegExp('^(?=.*[A-Z])'), t('validations:passwordUpper'))
        .matches(
          RegExp('^(?=.*[!@#$%*()_+=-?/{}<>])[^&^]*$'),
          t('validations:passwordChar'),
        ),
      confirm: Yup.string()
        .required(t('validations:passwordConfirm'))
        .oneOf(
          [Yup.ref('password'), null],
          t('validations:invalidPasswordConfirm'),
        ),
      code: Yup.string()
        .required(t('validations:requiredField')),  
      authorization: Yup.bool()
        .oneOf(
          [true], t('validations:requiredField')
        ),
    }),
    onSubmit: (values: FirstStepParams) => {
      alert(JSON.stringify(values, null, 2));
    },
  });

  const formikEmailClient = useFormik({
    validateOnMount: true,
    enableReinitialize: true,
    initialValues: {
      email1: '',
      password: '',
      confirm: '',
      code: '',
      authorization: false
    },
    validationSchema: Yup.object().shape({
      email1: Yup.string()
        .required(t('validations:requiredField'))
        .email(t('validations:invalidEmailClient')),
    }),
    onSubmit: (values: FirstStepParams) => {
      //alert(JSON.stringify(values, null, 2));
      window.open('https://painel.hostnet.com.br', '_blank');
    },
  });

  async function sendVerificationCode(formik: FormikProps<FirstStepParams>): Promise<boolean> {
    try {
      setCodeSentMessage('');

      if (formik.errors.email1) {
        formik.setFieldTouched('email1', true, true);
        return false;
      }  

      const data = {
        email: formik.values.email1,
      };
  
      await axios.post(`${baseURL}/verification-codes`, data);

      setCodeSentMessage(`O código foi enviado para o e-mail ${formik.values.email1}. Por favor, verifique sua caixa de entrada e spam.`);
      return true;
    } catch (error) {
      return false;
    } 
  }

  const formSubmit = async (values: any) => {
    const getParams: urlParams = {};

    if (produto) {
      getParams['produto'] = produto;
    }

    if (idRepresentante) {
      getParams['franchise_id'] = parseInt(idRepresentante);
    }

    if (representante) {
      getParams['representante'] = representante;
    }

    if (cupom) {
      getParams['cupom'] = cupom;
    }

    var data:any = {
      name: values.name,
      //cpf_cnpj: values.cpf_cnpj.replace(/[^a-zA-Z0-9 ]/g, ''),
      //identification: values.rg ? values.rg.replace(/[^a-zA-Z0-9 ]/g, '') : values.rg,
      birthdate: values.birthdate,
      account_type: values.account_type,
      email2: values.email2,
      ddi: values.ddi,
      mobile: values.mobile.replace(/[^a-zA-Z0-9 ]/g, ''),
      branch: values.branch,
      country: values.country,
      postalcode: values.postalcode.replace(/[^a-zA-Z0-9 ]/g, ''),
      address: values.address,
      number: values.number,
      neighborhood: values.neighborhood,
      address_complement: values.address_complement,
      city: values.city,
      state: values.state,
      responsible: values.responsible ? values.responsible : values.name,
      ...firstStepValues,
      ...getParams
    };

    if (values.country === 'Brazil') {
      data.cpf_cnpj = values.cpf_cnpj.replace(/[^a-zA-Z0-9 ]/g, '');
      data.identification = values.rg ? values.rg.replace(/[^a-zA-Z0-9 ]/g, '') : values.rg;
    }

    Swal.fire({
      title: "Criando conta...",
      html: "Estamos preparando sua conta, você será redirecionado em alguns segundos.",
      didOpen: () => {
        Swal.showLoading()
      },
      allowOutsideClick: false,
    });

    //console.log(data);

    axios.post(`${baseURL}/account`, data)
      .then(function (response) {
        Swal.close();
        window.location.href = response.data.loginUrl;
      })
      .catch(function (error) {
        Swal.close();
        Swal.fire({
          title: "Não foi possível concluir a criação da conta",	
          showConfirmButton: false,
          html: `
          <p>Por gentileza, entre em contato para que possamos concluir seu cadastro.</p>
          <div style="margin-top: 1rem; display: flex; flex-direction: column; align-items: center; gap: 10px;">
            <a href="https://wa.me/552120186703" target="_blank" rel="noopener noreferrer"
              style="width: fit-content; display: inline-block; background-color: #25D366; color: white; padding: 10px 20px; border-radius: 5px; text-align: center; text-decoration: none;">
              Falar com suporte via WhatsApp
            </a>
            <a href="https://www.hostnet.com.br/atendimento-ao-cliente/" style="font-size: 14px; color: #555; text-decoration: underline;">
              Outras opções de contato
            </a>
          </div>
          `,
          icon: 'warning',
        });
      }); 
  }

  return (
    <div className="main">
      <Card text="white" className="custom-bg-primary rounded-0 border-0 header-style ">
        <Container>
          <Card className="rounded-0 border-0 bg-transparent d-flex flex-column flex-md-row align-items-center">
            <Col md={3}>
              <Card.Body>
                <Card.Img src={logo} alt="Logo Hostnet" width="180" height="40" className="custom-cardLogo" />
              </Card.Body>
            </Col>
            <Col md={6}>
              <Card.Body className="d-none d-md-flex align-items-center justify-content-center">
                <Card.Text className="text-white title text-center text-lg-start">Hospede seu site ou registre seu domínio</Card.Text>
              </Card.Body>
            </Col>
            <Col md={3}>
              <Card.Body className="d-none d-md-flex flex-column  align-items-end justify-content-center">
                <div className="d-block m-0 ms-auto">
                  <Card.Text className="text-white mb-0">
                      <a href="https://www.hostnet.com.br/atendimento-ao-cliente/" 
                        target="blank" className="text-reset" >Central de atendimento:</a>
                    </Card.Text>
                  <Card.Text className="text-white mb-0 whatsapp-text">
                      <Whatsapp></Whatsapp> WhatsApp: (21) 2018-6703
                  </Card.Text>
                </div>
              </Card.Body>
            </Col>
          </Card>
        </Container>
      </Card>

      <Container>
        <div className="b-5">
          <Card className="mx-0 my-4 custom-cardTitle">
            <Card.Body>
              <Card.Title as="h3" className="mb-0 custom-color-primary"><span></span>{t('commons:accountInformation')}</Card.Title>
            </Card.Body>
          </Card>
          <Tab.Container id="left-tabs-example" defaultActiveKey="newAccount">  
            <Nav variant="pills" className="m-3">
              <Nav.Item className="nav-item me-1 mb-2 mb-md-0">
                <Nav.Link onClick={() => setTabAccountInfo('newAccount')} eventKey="newAccount" className="text-color-nav border-color-white-50 rounded-0">Abra sua conta</Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link
                  eventKey="client"
                  className="text-color-nav border-color-black-50 rounded-0"
                  onClick={() => {
                    let redirectUrl = "https://painel4.hostnet.com.br/contratar";
                    
                    if (produto) {
                      redirectUrl = `https://painel4.hostnet.com.br/assine?produto=${produto}`;
                    }
                    
                    window.open(redirectUrl, "_blank");
                  }}
                >
                  Já sou cliente
                </Nav.Link>
              </Nav.Item>
            </Nav>
        
            <Tab.Content>
              <Tab.Pane eventKey="newAccount">
                <Card className="m-3 rounded-0">
                  <Card.Body className="custom-bg-card">
                    <Form onSubmit={formik.handleSubmit} ref={formRefFirstStep}>
                      <Form.Group className="mb-3">
                        <Form.Label htmlFor="email1" className="col-form-label-sm">Email</Form.Label>
                        <Form.Control
                          id="email1"
                          name="email1"
                          type="email"
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.email1}
                          isInvalid={!!formik.touched.email1 && !!formik.errors.email1}
                          className="form-control form-control-sm rounded-0" />
                        <Form.Text id="emailHelpBlock" muted>
                          {t('validations:emailHelp')}
                        </Form.Text>
                        <Form.Control.Feedback type="invalid">
                          {formik.errors.email1}
                        </Form.Control.Feedback>
                      </Form.Group>

                      <Form.Group className="mb-3">
                        <Form.Label htmlFor="password" className="col-form-label-sm">Senha</Form.Label>
                        <Form.Control
                          id="password"
                          name="password"
                          type="password"
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.password}
                          isInvalid={!!formik.touched.password && !!formik.errors.password}
                          className="form-control form-control-sm rounded-0" />
                        <Form.Text id="passwordHelpBlock" muted>
                          {t('validations:passwordHelp')}
                        </Form.Text>
                        <Form.Control.Feedback type="invalid">
                          {formik.errors.password}
                        </Form.Control.Feedback>
                      </Form.Group>

                      <Form.Group className="mb-3">
                        <Form.Label htmlFor="confirm" className="col-form-label-sm">Confirmar senha</Form.Label>
                        <Form.Control
                          id="confirm"
                          name="confirm"
                          type="password"
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.confirm}
                          isInvalid={!!formik.touched.confirm && !!formik.errors.confirm}
                          className="form-control form-control-sm rounded-0" />
                        <Form.Control.Feedback type="invalid">
                          {formik.errors.confirm}
                        </Form.Control.Feedback>
                      </Form.Group>

                      <Form.Group className="mb-3">
                        <Form.Label htmlFor="code" className="col-form-label-sm">
                          {t('commons:verificationCode')} (Utilize o botão abaixo para enviar o código para seu e-mail.)
                        </Form.Label>
                        <Form.Control
                          id="code"
                          name="code"
                          placeholder={t('commons:code')}
                          maxLength={6}
                          className="form-control form-control-sm rounded-0"
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.code}
                          isInvalid={!!formik.touched.code && !!formik.errors.code}
                        />
                        <Form.Control.Feedback type="invalid">
                          {formik.errors.code}
                        </Form.Control.Feedback>
                        <VerificationCodeButton
                          id="send-code-button"
                          className="btn-sm mt-2"
                          sendCode={async () => sendVerificationCode(formik)}
                        />
                        {codeSentMessage && <div className='mt-1'>
                          <Form.Text className="text-success">{codeSentMessage}</Form.Text>
                        </div>}
                      </Form.Group>

                      <Form.Group>
                        <Form.Check 
                          id="authorization"
                          name="authorization"
                          type="checkbox"
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          checked={formik.values.authorization}
                          required
                          label={t('validations:authorization')}
                          isInvalid={!!formik.touched.authorization && !!formik.errors.authorization}
                          className="form-text" />
                        <Form.Control.Feedback type="invalid">
                          {formik.errors.authorization}
                        </Form.Control.Feedback>
                      </Form.Group>

                      <Form.Group>
                        <Form.Text id="privacyPolicyBlock">
                          <Card.Link href="https://www.hostnet.com.br/politica-de-privacidade/" target="_BLANK">Confira nossa política de privacidade</Card.Link>
                        </Form.Text>
                      </Form.Group>
                    </Form>
                  </Card.Body>
                </Card>
                <Button 
                  /*variant="warning"*/
                  type="button"
                  size="lg"
                  onClick={() => handleClickNextStep(formik.values)} 
                  disabled={!formik.isValid}
                  className="mx-3 mb-3 nextStep" >Próximo passo
                </Button>
              </Tab.Pane>
              <Tab.Pane eventKey="client">
              </Tab.Pane>
            </Tab.Content>
          </Tab.Container>

          <div ref={formRef}></div>
          
          {isShown && tabAccountInfo === 'newAccount' && (
            <>
              <Card className="mx-0 my-4 custom-cardTitle">
                <Card.Body>
                  <Card.Title as="h3" className="mb-0 custom-color-primary"><span></span>{t('commons:personalInformation')}</Card.Title>
                </Card.Body>
              </Card>

              <Tab.Container id="left-tabs-example" defaultActiveKey="PessoaFisica">  
                <Nav variant="pills" className="m-3">
                  <Nav.Item className="nav-item me-1 mb-2 mb-md-0">
                    <Nav.Link eventKey="PessoaFisica" className="text-color-nav border-color-white-50 rounded-0">Pessoa Física</Nav.Link>
                  </Nav.Item>
                  <Nav.Item className='me-1'>
                    <Nav.Link eventKey="PessoaJuridica" className="text-color-nav border-color-black-50 rounded-0">Pessoa Jurídica</Nav.Link>
                  </Nav.Item>
                  
                </Nav>
                <Tab.Content>
                  <Tab.Pane eventKey="PessoaFisica">
                    {<FormPF formSubmit={formSubmit} f={formRefFirstStep} />}
                  </Tab.Pane>
                  <Tab.Pane eventKey="PessoaJuridica">
                    {<FormPJ formSubmit={formSubmit} f={formRefFirstStep} />}
                  </Tab.Pane>
                  
                </Tab.Content>
              </Tab.Container>
            </>
          )}
        </div>
      </Container>
    </div>
  );
};

export default Home;
